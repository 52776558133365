<template>
  <div v-if="url && listNav && listNav.length === 0">
    <div class="w-full px-4 py-3.5 text-14px leading-6 font-medium text-bw-32">
      <nuxt-link :to="url" class="text-black text-15px leading-6 font-medium">{{ title }}</nuxt-link>
    </div>
  </div>
  <div v-else>
    <div
        v-if="listNav && listNav.length > 0"
        class="relative flex items-center w-full border-#CBCBCB px-4 py-3.5 text-14px leading-6 font-medium text-bw-32"
    >
      <div
          ref="modelRef"
          @click.stop="openList = !openList"
          class="flex items-center justify-between w-full cursor-pointer"
      >
        <nuxt-link :to="url" class="text-black text-15px leading-6 font-medium">{{ title }}</nuxt-link>
        <span class="text-#B3b3b3 w-5.5 h-5.5 i-custom-chevron-down"> </span>
      </div>

      <ul
          class="absolute top-full left-0 right-0 w-full border border-#cbcbcb rounded-bl-2 rounded-br-2 overflow-hidden flex flex-col max-h-50 scrollEvent scroll-smooth overflow-y-scroll z-20 bg-white"
          style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 1px 0px"
          v-if="openList"
      >
        <template v-for="(item, index) in listNav">
          <li class="block w-full" @click="openList = false">
            <ItemNavMenu
                :title="item.name"
                :url="item.url"
                :data="item.childs"
            />
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import ItemNavMenu from './itemNavMenu.vue'
import {ref} from "vue";

const props = defineProps({
  title: {
    type: String
  },
  url: {
    type: String
  },
  listNav: {
    type: Array
  }
});
let listNav = props?.listNav?.filter(item => item?.url != null)
const openList = ref<boolean>(false);
</script>

<style></style>
